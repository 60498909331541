import React from 'react'
import '../App.css'

const Navbar = () => {
  return (
    <div className='navbar'>
        <h1>EarthShopping</h1>
    </div>
  )
}

export default Navbar