import React, { useState, useEffect } from "react";
import "../App.css";
import axios from "axios";

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);


    var param = {
      "jsonrpc": "2.0",
      "method": "call",
      "params": {
        "model": "",
        "method": "",
        "args": {
          "product_name": "",
          "off_set": 0
        },
        "kwargs": {

        }
      }
    };

    axios.post('https://erp.aacc-th.com/aacc/get_product_list?db=TestUAP',param)
      .then((res) => {
        console.log(res.data);
        setData(res.data.result.product);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="products-container">
      {loading && (
        <div>
          {" "}
          <h1>Loading...</h1>
        </div>
      )}

      {data.map((product) => (
        <div key={product.id} className="card">
          <div><img src={`https://erp.aacc-th.com/aacc/get_product_image/${product.id}?db=TestUAP`} 
          alt="#" onError={( e ) => {
         e.target.onError = null;
         e.target.src="https://t4.ftcdn.net/jpg/04/00/24/31/360_F_400243185_BOxON3h9avMUX10RsDkt3pJ8iQx72kS3.jpg";
  }} /></div>
          <div className="card-description">
            
            <h6>{`รหัสสินค้า: ${product.id}`}</h6>
            <h6>{`ชื่อสินค้า: ${product.name}`}</h6>
            <h6>{`ราคา: ${product.list_price}`}</h6>
            

            
          </div>
        </div>
      ))}
    </div>
  );
};

export default Products;
