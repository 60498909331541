import React from "react";
import '../App.css'

const Footer = () => {
  return (
    <div className="footer">
      <p>Shopping App 2022&copy;</p>
    </div>
  );
};

export default Footer;
